<template>
  <div>
    <ValidationObserver
      v-slot="{ invalid }"
      ref="validationObserver"
    >
      <edit-dialog
        :value="value"
        max-width="1200"
        headline-text="Merge Boxes"
        :inputs-valid="!invalid"
        accept-text="Merge"
        @accept="applyBoxMerge"
        @input="$emit('input', $event)"
      >
        <p>
          Select the boxes you would like to merge into <strong>{{ inventoryBox.name }}</strong>.<br>
          The current weight is <strong>{{ totalWeight | formatNumber }} lbs</strong> with a total of <strong>{{ totalPieces | formatNumber }} pieces</strong>.
        </p>
        <inventory-box-select
          v-if="value"
          v-model="selectedBoxes"
          :search-filters="searchFilters"
          :exclude-box-ids="[inventoryBox.id]"
        />
      </edit-dialog>
    </ValidationObserver>
  </div>
</template>

<script>
import { mergeInventoryBox } from '../../../requests/inventoryBoxes'
import InventoryBoxSelect from './InventoryBoxSelect.vue'

export default {
  name: 'MergeBoxDialog',
  components: { InventoryBoxSelect },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    inventoryBox: {
      type: Object,
      required: true
    },
    // typically filtering should be for current location
    searchFilters: {
      type: Object,
      default: Object
    }
  },
  data: () => ({
    selectedBoxes: []
  }),
  computed: {
    totalWeight () {
      return this.selectedBoxes.reduce((accum, box) => accum + Number(box.weight), Number(this.inventoryBox.weight))
    },
    totalPieces () {
      return this.selectedBoxes.reduce((accum, box) => accum + Number(box.pieces), Number(this.inventoryBox.pieces))
    }
  },
  methods: {
    applyBoxMerge () {
      if (!this.selectedBoxes) {
        this.$store.commit('setSnackbarError', 'No boxes provided')
        return
      }
      const boxIds = this.selectedBoxes.map(box => box.id)
      mergeInventoryBox(this.inventoryBox.id, boxIds).then(response => {
        // The page should be reloaded
        if (response.status === 200) {
          this.selectedBoxes = []
          this.$emit('merged')
          this.$store.commit('setSnackbarSuccess', 'Boxes Merged')
        } else {
          this.$store.commit('setSnackbarWarning', 'Box Merge Failed')
        }
      })
    }
  }
}
</script>
