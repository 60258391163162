var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('unauthorized',{attrs:{"authorized":_vm.$store.getters.can('view-inventory-on-hand'),"message":"Unauthorized to view Inventory on Hand"}},[(!_vm.box)?_c('div',[_c('v-skeleton-loader',{attrs:{"loading":_vm.loading,"type":"heading, list-item@5, table"}},[_vm._v(" Unable to load box ")])],1):_vm._e(),(_vm.box)?_c('v-container',{staticClass:"mb-16",attrs:{"fluid":""}},[_c('url-breadcrumbs',{attrs:{"items":[
        ['Inventory On Hand', { name: 'inventory-on-hand' }],
        ['Inventory Box', { name: 'inventory-on-hand-box', params: { id: _vm.id }}]
      ]}}),(_vm.box.status === 'ST')?_c('v-alert',{attrs:{"outlined":"","dense":"","color":"warning"}},[_vm._v(" This box is staged and can only be edited in Lot Inventory. ")]):_vm._e(),_c('v-row',[_c('v-col',[_c('base-title',{scopedSlots:_vm._u([{key:"subtitle",fn:function(){return [(_vm.box && _vm.box.inventory)?_c('div',[_c('info-tooltip',{scopedSlots:_vm._u([{key:"wrap-me",fn:function(){return [_c('span',{staticClass:"text-sm-caption"},[_c('router-link-id',{attrs:{"id":_vm.box.inventory.id,"name":"inventory"}},[_vm._v(" "+_vm._s(_vm.box.inventory.name)+" ")])],1)]},proxy:true}],null,false,1208656557)},[_vm._v(" Linked Lot Inventory ")])],1):_vm._e(),_vm._v(" "+_vm._s(_vm.box.buyers.join(', '))+" ")]},proxy:true}],null,false,1488475704)},[(_vm.box && _vm.box.name)?_c('span',[_c('info-tooltip',{scopedSlots:_vm._u([{key:"wrap-me",fn:function(){return [_vm._v(" "+_vm._s(_vm.box.name)+" ")]},proxy:true}],null,false,3033397469)},[_vm._v(" #"+_vm._s(_vm.id)+" ")])],1):_vm._e()])],1),_c('v-col',{staticClass:"text-right"},[(_vm.box)?_c('print-inventory-box',{attrs:{"usernames":_vm.box.buyers,"box-name":_vm.box.name,"location-name":_vm.box.location_name || '',"gps-number":_vm.box.gps_number || '',"gross":_vm.box.weight,"net":_vm.box.net,"tare":_vm.box.tare,"pieces":String(_vm.box.pieces),"vendors-text":_vm.box.contains,"lots-text":_vm.box.in_lots},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('v-btn',{attrs:{"small":""},on:{"click":on}},[_vm._v(" print "),_c('v-icon',{staticClass:"pl-2",attrs:{"small":""}},[_vm._v(" mdi-printer-outline ")])],1)]}}],null,false,1004085869)}):_vm._e()],1)],1),_c('br'),(_vm.loading)?_c('v-skeleton-loader'):(!_vm.box)?_c('div',[_vm._v(" Unable to load data ")]):_c('div',[_c('h3',{staticClass:"text-caption grey--text"},[_vm._v(" Status ")]),_c('p',[_vm._v(_vm._s(_vm.box.status_text)+" "),_c('small',[_vm._v("("+_vm._s(_vm.box.is_on_hand ? 'On Hand' : 'Not On Hand')+")")])]),_c('h3',{staticClass:"text-caption grey--text"},[_vm._v(" Catalog ")]),_c('p',[_vm._v(_vm._s(_vm.box.catalog_name || 'No Catalog / Material Type'))]),_c('h3',{staticClass:"text-caption grey--text"},[_vm._v(" Location / GPS ")]),_c('p',[_vm._v(_vm._s(_vm.box.location_name || 'No Location')),(_vm.box.gps_number)?_c('span',[_vm._v(" / "+_vm._s(_vm.box.gps_number))]):_vm._e()]),_c('h3',{staticClass:"text-caption grey--text"},[_vm._v(" Notes ")]),_c('p',[_vm._v(_vm._s(_vm.box.notes || ''))]),_c('v-skeleton-loader',{attrs:{"loading":_vm.loading,"type":"table"}},[_c('h3',{staticClass:"text-caption grey--text mb-2"},[_vm._v(" Change History ")]),_c('inventory-box-table',{attrs:{"headers":[
            'name',
            'status',
            'location_name',
            'manifest',
            'contains',
            'in_lots',
            'catalog_name',
            'weight',
            'tare',
            'net',
            'pieces',
            'eff_start_datetime'
          ],"no-name-link":"","historic":true},model:{value:(_vm.history),callback:function ($$v) {_vm.history=$$v},expression:"history"}})],1),_c('div',{staticClass:"text-caption text-right mt-8"},[_vm._v(" Last Updated on "+_vm._s(_vm._f("formatTimeDate")(_vm.box.updated_at))+" ")])],1),(_vm.box.is_on_hand)?_c('base-speed-dial',{attrs:{"direction":"left","icon":this.box.is_locked ? 'mdi-lock' : 'mdi-pencil',"open-on-hover":""}},[_c('v-btn',{attrs:{"disabled":!_vm.$store.getters.can('edit-inventory-boxes') || this.box.is_locked,"fab":"","color":"primary"},on:{"click":function($event){return _vm.$router.push({ name: 'inventory-on-hand-box-edit', params: { id: _vm.box.id }})}}},[_c('v-icon',[_vm._v(" mdi-pencil ")])],1),_c('v-btn',{attrs:{"disabled":!_vm.$store.getters.can('edit-inventory-boxes') || this.box.is_locked,"fab":""},on:{"click":function($event){_vm.splitDialogLazy = true; _vm.splitDialog = !_vm.splitDialog}}},[_c('v-icon',[_vm._v(" mdi-call-split ")])],1),_c('v-btn',{attrs:{"disabled":!_vm.$store.getters.can('edit-inventory-boxes') || this.box.is_locked,"fab":""},on:{"click":function($event){_vm.mergeDialogLazy = true; _vm.mergeDialog = !_vm.mergeDialog}}},[_c('v-icon',[_vm._v(" mdi-call-merge ")])],1),_c('v-btn',{attrs:{"disabled":!_vm.$store.getters.can('edit-inventory-boxes') || this.box.is_locked,"fab":"","color":"error"},on:{"click":_vm.deleteBox}},[_c('v-icon',[_vm._v(" mdi-delete ")])],1)],1):_vm._e()],1):_vm._e(),(_vm.mergeDialogLazy)?_c('merge-box-dialog',{attrs:{"inventory-box":_vm.box,"search-filters":_vm.box.location_name ? { location_name: _vm.box.location_name } : {}},on:{"merged":_vm.loadData},model:{value:(_vm.mergeDialog),callback:function ($$v) {_vm.mergeDialog=$$v},expression:"mergeDialog"}}):_vm._e(),(_vm.splitDialogLazy)?_c('split-location-dialog',{attrs:{"inventory-box":_vm.box},model:{value:(_vm.splitDialog),callback:function ($$v) {_vm.splitDialog=$$v},expression:"splitDialog"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }