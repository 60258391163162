<template>
  <div>
    <ValidationObserver
      v-slot="{ invalid }"
      ref="validationObserver"
    >
      <edit-dialog
        :value="value"
        headline-text="Split Box"
        :inputs-valid="!invalid"
        accept-text="Split"
        @accept="applyBoxSplit"
        @input="$emit('input', $event)"
      >
        <p>
          Enter the allocated number of pieces and weight, other fields may be modified after splitting.
        </p>
        <v-simple-table>
          <tbody>
            <tr>
              <td />
              <td>
                {{ inventoryBox.weight | formatNumber }} lbs
                <v-icon
                  small
                  :color="combinedWeight >= inventoryBox.weight ? 'success' : 'warning'"
                >
                  {{ combinedWeight >= inventoryBox.weight ? 'mdi-check-circle-outline' : 'mdi-circle-outline' }}
                </v-icon>
              </td>
              <td>
                {{ inventoryBox.pieces | formatNumber }}
                <v-icon
                  small
                  :color="combinedPieces >= inventoryBox.pieces ? 'success' : 'warning'"
                >
                  {{ combinedPieces >= inventoryBox.pieces ? 'mdi-check-circle-outline' : 'mdi-circle-outline' }}
                </v-icon>
              </td>
            </tr>
            <tr>
              <td>
                {{ inventoryBox.name }}
              </td>
              <td>
                <ValidationProvider
                  rules="required|two_decimal"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    v-model="originBoxWeight"
                    type="number"
                    label="Weight lbs"
                    :error-messages="errors"
                  />
                </ValidationProvider>
              </td>
              <td>
                <ValidationProvider
                  rules="required|decimal:0"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    v-model="originBoxPieces"
                    type="number"
                    label="Pieces"
                    :error-messages="errors"
                  />
                </ValidationProvider>
              </td>
            </tr>
            <tr>
              <td>New Box</td>
              <td>
                <ValidationProvider
                  rules="required|two_decimal"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    v-model="newBoxWeight"
                    label="Weight lbs"
                    type="number"
                    :error-messages="errors"
                  />
                </ValidationProvider>
              </td>
              <td>
                <ValidationProvider
                  rules="required|decimal:0"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    v-model="newBoxPieces"
                    label="Pieces"
                    type="number"
                    :error-messages="errors"
                  />
                </ValidationProvider>
              </td>
            </tr>
            <tr>
              <td />
              <td>
                {{ combinedWeight | formatNumber }} lbs
              </td>
              <td>
                {{ combinedPieces | formatNumber }}
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </edit-dialog>
    </ValidationObserver>
    <confirm-dialog
      v-if="newBox"
      v-model="navigateToNewBoxDialog"
      @accept="() => $router.push({ name: 'inventory-on-hand-box', params: { id: newBox.id } })"
    >
      Do you want to navigate to {{ newBox.name }}?
    </confirm-dialog>
  </div>
</template>

<script>
import { splitInventoryBox } from '../../../requests/inventoryBoxes'
import ConfirmDialog from '../../../base/components/ConfirmDialog.vue'

export default {
  name: 'SplitBoxDialog',
  components: { ConfirmDialog },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    inventoryBox: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    originBoxWeight: 0,
    newBoxWeight: 0,
    newBoxPieces: 0,
    originBoxPieces: 0,
    navigateToNewBoxDialog: false,
    newBox: null
  }),
  computed: {
    combinedWeight () {
      return Number(this.originBoxWeight) + Number(this.newBoxWeight)
    },
    combinedPieces () {
      return Number(this.originBoxPieces) + Number(this.newBoxPieces)
    },
    checkIns () {
      return this.inventoryBox.check_ins_data
    }
  },
  watch: {
    /**
     * Updates variables to default on component open
     */
    value (opened) {
      if (opened) {
        this.newBoxWeight = 0
        this.originBoxWeight = this.inventoryBox.weight
        this.originBoxPieces = this.inventoryBox.pieces
        this.newBoxWeight = 0
        this.newBoxPieces = 0
      }
    }
  },
  methods: {
    /**
     * Applies the box split operation
     */
    applyBoxSplit () {
      splitInventoryBox(this.inventoryBox.id, this.originBoxWeight, this.originBoxPieces, this.newBoxWeight, this.newBoxPieces).then(response => {
        if (response.data && response.data.new && response.data.new.name) {
          this.$emit('input', false)
          this.$emit('split')
          this.$store.commit('setSnackbarSuccess', 'Created Inventory Box ' + response.data.new.name)
          this.newBox = response.data.new
          this.navigateToNewBoxDialog = true
        }
      })
    }
  }
}
</script>
