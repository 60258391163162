<template>
  <div>
    <ValidationObserver
      v-slot="{ invalid }"
      ref="validationObserver"
    >
      <edit-dialog
        :value="value"
        headline-text="Create Inventory Item"
        accept-text="Create Box"
        :inputs-valid="!!(weight || pieces)"
        @input="$emit('input', $event)"
        @accept="create"
      >
        <rules-text
          v-model="weight"
          label="Weight in lbs"
          validation-rules="required|max_value:99999999|decimal:2"
          type="number"
        />
        <rules-text
          v-model="pieces"
          label="Pieces"
          validation-rules="required|max_value:99999999|decimal"
          type="number"
        />
        <v-select
          v-model="location"
          label="Location (optional)"
          hint="Uses 'Locations' marked as inventory_location"
          persistent-hint
          clearable
          item-text="name"
          item-value="url"
          :items="locations"
        />
        <search-autocomplete
          v-model="catalog"
          clearable
          label="Lookup Catalog Item (optional)"
          api-path="/api/catalogs/"
          :format-entry="x => (Object.assign({}, x, { friendlyName: `${x.id} ${x.name}` }))"
        />
      </edit-dialog>
    </ValidationObserver>
  </div>
</template>
<script>

import RulesText from '@/components/ValidationFields/RulesText.vue'
import { createInventoryBox, fetchInventoryLocations } from '../../../requests/inventoryBoxes'
import SearchAutocomplete from '../../../base/components/SearchAutocomplete.vue'

export default {
  name: 'CreateInventoryBoxDialog',
  components: { SearchAutocomplete, RulesText },
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    weight: 0,
    pieces: 0,
    locationObj: null,
    locations: [],
    location: null,
    catalog: null
  }),
  watch: {
    value (opened) {
      if (opened) {
        this.weight = 0
        this.pieces = 0
        fetchInventoryLocations().then(response => {
          this.locations = response.data
        })
      }
    }
  },
  methods: {
    create () {
      const payload = {
        weight: this.weight,
        pieces: this.pieces
      }
      if (this.location) payload.location = this.location
      if (this.catalog) payload.catalog = this.catalog.url
      createInventoryBox(payload).then(response => {
        if (response.status === 201) {
          this.$emit('created')
          this.$store.commit('setSnackbarSuccess', 'Inventory Box created ' + response.data.name)
        }
      })
    }
  }
}
</script>
