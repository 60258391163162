<template>
  <unauthorized
    :authorized="$store.getters.can('view-inventory-on-hand')"
    message="Unauthorized to view Inventory on Hand"
  >
    <ValidationObserver
      ref="observer"
      v-slot="{ invalid }"
    >
      <v-form @submit.stop.prevent="save">
        <div v-if="!box">
          <v-skeleton-loader
            :loading="loading"
            type="heading, list-item@5, table"
          >
            Unable to load box
          </v-skeleton-loader>
        </div>
        <v-container
          v-if="box"
          fluid
          class="mb-16"
        >
          <url-breadcrumbs
            :items="[
              ['Inventory On Hand', { name: 'inventory-on-hand' }],
              ['Inventory Box', { name: 'inventory-on-hand-box', params: { id }}],
              ['Edit', { name: 'inventory-on-hand-box-edit', params: { id }}]
            ]"
          />
          <base-title>
            Inventory Box <span v-if="box && box.name">{{ box.name }}</span>
            <template v-slot:subtitle>
              <small class="grey--text">#{{ id }}</small>
              <br>
              {{ box.status_text }} <small>({{ box.is_on_hand ? 'On Hand' : 'Not On Hand' }})</small>
            </template>
          </base-title>
          <div v-if="!box">
            <v-skeleton-loader
              :loading="loading"
              type="heading, list-item@5, table"
            >
              Unable to load data
            </v-skeleton-loader>
          </div>
          <div v-else>
            <rules-text
              v-model="weight"
              validation-rules="required|min_value:0|max_value:99999999|two_decimal"
              label="gross lbs"
            />
            <rules-text
              v-model="tare"
              validation-rules="required|min_value:0|max_value:99999999|two_decimal"
              label="tare lbs"
            />
            <rules-text
              disabled
              v-model="net"
              validation-rules="required|min_value:0|max_value:99999999|two_decimal"
              label="net lbs"
            />
            <rules-text
              v-model="pieces"
              validation-rules="required|min_value:0|decimal:0"
              label="pieces"
            />
            <v-select
              v-model="locationUrl"
              label="Location"
              clearable
              :items="locations"
              item-text="name"
              item-value="url"
            />
            <rules-text
              validation-rules="max:20"
              v-model="gps"
              label="GPS"
            />
            <v-select
              v-model="catalog"
              item-text="name"
              item-value="url"
              label="Catalog"
              clearable
              :items="catalogOptions"
            />
            <span>
              Designated Lots
            </span>
            <v-list dense>
              <v-list-item
                v-for="(dl, index) in designatedLots"
                :key="index"
              >
                <v-list-item-title>
                  {{ dl.name }}
                </v-list-item-title>
                <v-list-item-action>
                  <v-btn
                    x-small
                    @click="removeDesignatedLot(dl.url)"
                  >
                    remove
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
            </v-list>
            <search-autocomplete
              label="Add Designated Lot"
              item-text="name"
              api-path="/api/lots/"
              :params="{ fields: 'url,id,name' }"
              :format-entry="lot => Object.assign({}, { friendlyName: lot.name }, lot)"
              @input="addDesignatedLot"
            />
            <v-row>
              <v-col />
            </v-row>
            <ValidationProvider
              v-slot="{ errors }"
            >
              <v-textarea
                label="Notes"
                v-model="notes"
                counter
                auto-grow
                :error-messages="errors"
              />
            </ValidationProvider>
            <h3>Add / Remove Check Ins</h3>
            <div class="text-caption">
              Only selected items will be saved.
            </div>
            <v-card>
              <v-card-title>
                <search-autocomplete
                  label="Add Check In (Search)"
                  api-path="/api/check_ins/"
                  :format-entry="x => (Object.assign({}, x, { friendlyName: `${x.id} ${x.yard_name}` }))"
                  @input="addCheckIn"
                />
              </v-card-title>
              <v-data-table
                v-model="checkInsSelected"
                :headers="checkInHeaders"
                :items="checkInList"
                show-select
                disable-pagination
                hide-default-footer
              >
                <template v-slot:item.id="{ item }">
                  {{ item.id }}
                </template>
              </v-data-table>
            </v-card>
          </div>
          <text-fab
            :disabled="invalid"
            bottom
            right
            fixed
            icon-text="mdi-check"
            color="primary"
            type="submit"
          >
            Save
          </text-fab>
        </v-container>
      </v-form>
    </ValidationObserver>
  </unauthorized>
</template>

<script>
import {
  fetchCatalogOptions,
  fetchInventoryBox,
  fetchInventoryLocations,
  patchInventoryBox
} from '../../requests/inventoryBoxes'
import SearchAutocomplete from '../../base/components/SearchAutocomplete.vue'
import RulesText from '../../components/ValidationFields/RulesText.vue'
import axios from 'axios'

export default {
  name: 'InventoryBoxEditView',
  components: { RulesText, SearchAutocomplete },
  data: () => ({
    id: 0,
    box: null,
    loading: false,
    notes: '',
    gps: '',
    locationUrl: '',
    locations: [],
    weight: 0,
    tare: 0,
    net: 0,
    pieces: 0,
    catalogOptions: [],
    catalog: null,
    checkInList: [],
    checkInsSelected: [],
    designatedLots: [],
    lotSearch: null,
    checkInHeaders: [
      { text: 'ID', value: 'id' },
      { text: 'Yard', value: 'yard_name' }
    ]
  }),
  watch: {
    weight (v) {
      const gross = v || 0
      const tare = this.tare || 0
      this.net = gross - tare
    },
    tare (v) {
      const gross = this.weight || 0
      const tare = v || 0
      this.net = gross - tare
    },
    lotSearch (lot) {
      console.log(lot)
    }
  },
  methods: {
    addDesignatedLot (lot) {
      if (!lot || this.designatedLots.find(l => l.url === lot.url)) return
      this.designatedLots.push(lot)
    },
    removeDesignatedLot (url) {
      this.designatedLots = this.designatedLots.filter(l => l.url !== url)
    },
    addCheckIn (checkIn) {
      if (!this.checkInList.find(ci => ci.id === checkIn.id)) {
        this.checkInList.push(checkIn)
        this.checkInsSelected.push(checkIn)
      }
    },
    save () {
      const id = this.box.id
      const payload = {
        weight: this.weight,
        tare: this.tare,
        net: this.net,
        pieces: this.pieces,
        gps_number: this.gps,
        notes: this.notes,
        location: this.locationUrl,
        check_ins: this.checkInsSelected.map(ci => ci.url),
        catalog: this.catalog || null,
        designated_lots: this.designatedLots.map(dl => dl.url)
      }
      patchInventoryBox(id, payload).then(response => {
        if (response.status === 200) {
          this.$store.commit('setSnackbarSuccess', this.box.name + ' updated')
          this.$router.push({ name: 'inventory-on-hand-box', params: { id } })
        } else {
          this.$store.commit('setSnackbarError', this.box.name + ' update failed')
        }
      })
    },
    async loadData () {
      this.id = this.$route.params.id
      await fetchInventoryBox(this.$route.params.id).then(response => {
        const b = response.data
        this.box = b
        this.weight = b.weight
        this.tare = b.tare
        this.net = b.net
        this.pieces = b.pieces
        this.gps = b.gps_number || ''
        this.notes = b.notes
        this.locationUrl = b.location
        this.catalog = b.catalog
        this.designatedLots = b.designated_lots_data
        // For each promise, do an axios request, when all resolved map and reduce responses
        const promises = b.check_ins.map(ci => axios.get(ci))
        Promise.all(promises).then(responses => {
          this.checkInList = responses.reduce((accum, res) => {
            if (res.data) accum.push(res.data)
            return accum
          }, [])
          this.checkInsSelected = this.checkInList
        })
      })
      await fetchInventoryLocations().then(response => {
        this.locations = response.data
      })
      await fetchCatalogOptions().then(response => {
        this.catalogOptions = response.data
      })
    }
  },
  async mounted () {
    this.loading = true
    await this.loadData()
    this.loading = false
  }
}
</script>
