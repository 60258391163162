<template>
  <unauthorized
    :authorized="$store.getters.can('view-inventory-on-hand')"
    message="Unauthorized to view Inventory on Hand"
  >
    <v-container
      fluid
      class="mb-16"
    >
      <base-title>
        Inventory On Hand
      </base-title>
      <v-alert
        v-if="actionMode"
        border="left"
        :color="enableBoxCutting ? 'error' : 'info'"
        dense
        outlined
      >
        <span v-show="actionMode === 'MOVE'">Click on lines to move inventory to {{ destinationLocation }}</span>
        <span v-show="actionMode === 'CUT'">Clicking on boxes will mark them as cut.</span>
        <template v-slot:append>
          <v-btn
            small
            @click="clearModes(true)"
          >
            Done
          </v-btn>
        </template>
      </v-alert>
      <v-form @submit.prevent="applyFiltersAndRoute">
        <v-row>
          <v-col
            cols="12"
            sm="8"
          >
            <div class="pt-2 d-flex align-center">
              <div>
                <div class="d-inline-block">
                  <v-menu offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        text
                        v-bind="attrs"
                        v-on="on"
                      >
                        {{ locationName }}
                        <v-icon
                          class="pl-2"
                          small
                        >
                          mdi-city-switch
                        </v-icon>
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item-group
                        v-model="selectedLocation"
                      >
                        <v-list-item
                          v-for="(loc, i) in locationChoices"
                          :key="i"
                        >
                          {{ loc }}
                        </v-list-item>
                      </v-list-item-group>
                    </v-list>
                  </v-menu>
                  <div
                    v-if="filterDate"
                    class="pl-4 text-sm-caption"
                  >
                    {{ filterDate | formatDate }}
                  </div>
                </div>
              </div>
            </div>
          </v-col>
          <v-col
            cols="12"
            sm="4"
            class="text-right"
          >
            <v-text-field
              v-model="search"
              class="pt-0"
              prepend-icon="mdi-tune-variant"
              hide-details
              single-line
              label="Search"
              clearable
              @click:prepend="showFilters = !showFilters"
            />
          </v-col>
        </v-row>
        <v-expand-transition>
          <v-card
            v-show="showFilters"
            outlined
          >
            <v-card-title>
              Additional Filters
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col
                  cols="12"
                  md="4"
                >
                  <rules-date
                    v-model="filterDate"
                    validation-rules=""
                    clearable
                    persistent-hint
                    :max="today"
                    hint="Show inventory for specific date"
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="4"
                >
                  <v-select
                    v-model="filterManifestIsNull"
                    label="Has Manifest"
                    :items="filterManifestOptions"
                  />
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <info-tooltip>
                <template v-slot:wrap-me>
                  <download-excel
                    v-if="canExport"
                    worksheet="Sheet1"
                    :fetch="fetchInventoryExport"
                    :name="`inventory_${locationName.toLowerCase()}_${today}.xls`"
                  >
                    <v-btn
                      class="mx-2"
                      :loading="loadingExportData"
                      small
                    >
                      Export
                    </v-btn>
                  </download-excel>
                  <v-btn
                    v-else
                    disabled
                    class="mx-2"
                    small
                  >
                    Export
                  </v-btn>
                </template>
                <span v-if="locationName === 'All Locations'">
                  Requires 'export-full-inventory'
                </span>
                <span v-else>
                  Requires 'export-inventory'
                </span>
              </info-tooltip>
              <v-spacer />
              <v-btn
                small
                color="primary"
                type="submit"
              >
                Apply
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-expand-transition>
      </v-form>
      <v-sheet class="d-flex justify-space-between">
        <v-simple-table>
          <thead>
            <tr>
              <th>Boxes</th>
              <th>Gross Weight</th>
              <th>Pieces</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>{{ meta.boxes | formatNumber }}</th>
              <th>{{ meta.weight | formatNumber }} lbs</th>
              <th>{{ meta.pieces | formatNumber }}</th>
            </tr>
          </tbody>
        </v-simple-table>
      </v-sheet>
      <inventory-box-table
        class="mt-4"
        v-model="data"
        :headers="[
          'name',
          'status',
          'location_name',
          'manifest',
          'contains',
          'in_lots',
          'buyers',
          'catalog_name',
          'weight',
          'pieces'
        ]"
        @rowClicked="rowClickHandler"
      />
      <v-row>
        <v-col
          cols="12"
          offset="0"
          md="8"
          offset-md="2"
        >
          <v-pagination
            v-model="page"
            :length="pagesLength"
            @input="() => applyFiltersAndRoute(false)"
          />
        </v-col>
      </v-row>
    </v-container>
    <base-speed-dial
      direction="left"
      icon="mdi-plus"
      open-on-hover
    >
      <v-btn
        :disabled="!$store.getters.can('edit-inventory-boxes')"
        rounded
        color="primary"
        @click="showInventoryBox = !showInventoryBox"
      >
        <v-icon>
          mdi-package-variant-closed-plus
        </v-icon>
      </v-btn>
      <v-btn
        :disabled="!$store.getters.can('edit-inventory-boxes')"
        rounded
        color="primary"
        @click="showInventoryLocation = !showInventoryLocation"
      >
        <v-icon>
          mdi-domain-plus
        </v-icon>
      </v-btn>
      <v-btn
        :disabled="moveToChoices.length === 0 || !$store.getters.can('edit-inventory-boxes')"
        rounded
        color="primary"
        @click="moveInventoryDialog = !moveInventoryDialog"
      >
        <v-icon>
          mdi-transfer
        </v-icon>
      </v-btn>
      <v-btn
        v-if="isShreddingLocation"
        :disabled="moveToChoices.length === 0 || !$store.getters.can('edit-inventory-boxes')"
        rounded
        color="error"
        @click="setEnableBoxCutting(true)"
      >
        <v-icon>
          mdi-package-variant-remove
        </v-icon>
      </v-btn>
    </base-speed-dial>
    <create-inventory-box-dialog
      v-model="showInventoryBox"
      @created="fetchBoxes"
    />
    <create-inventory-location-dialog
      v-model="showInventoryLocation"
      @created="fetchLocations"
    />
    <select-location-dialog
      v-model="moveInventoryDialog"
      :locations="moveToChoices"
      @selected="setDestinationLocation"
    >
      Select a location to enter Move Inventory mode.
    </select-location-dialog>
  </unauthorized>
</template>

<script>
import InventoryBoxTable from './components/InventoryBoxTable.vue'
import RulesDate from '../../components/ValidationFields/RulesDate.vue'
import moment from 'moment/moment'
import _ from 'underscore'
import BaseSpeedDial from '../../components/BaseSpeedDial.vue'
import CreateInventoryBoxDialog from './components/CreateInventoryBoxDialog.vue'
import { fetchInventoryBoxes, fetchInventoryLocations, patchInventoryBox } from '../../requests/inventoryBoxes'
import CreateInventoryLocationDialog from '../../components/CreateInventoryLocationDialog.vue'
import SelectLocationDialog from '@/views/Warehouse/components/SelectLocationDialog.vue'

const today = moment().format('YYYY-MM-DD')
export default {
  name: 'InventoryOnHandView',
  components: {
    SelectLocationDialog,
    CreateInventoryLocationDialog,
    CreateInventoryBoxDialog,
    BaseSpeedDial,
    RulesDate,
    InventoryBoxTable
  },
  data: () => ({
    completeExportData: null,
    loadingExportData: false,
    filterManifestOptions: [
      { text: 'Any', value: '' },
      { text: 'Yes', value: false },
      { text: 'No', value: true }
    ],
    filterManifestIsNull: '',
    addInventoryBoxDialog: false,
    showInventoryLocation: false,
    moveInventoryDialog: false,
    enableBoxCutting: false,
    destinationLocation: '',
    locationMeta: [],
    data: [],
    meta: {
      boxes: 0,
      pieces: 0,
      weight: 0
    },
    editInventoryDialog: false,
    filterDate: null,
    locationName: 'All Locations',
    page: 1,
    pagesLength: 0,
    search: '',
    selectedLocation: 0,
    showFilters: false,
    showInventoryBox: false,
    callback: () => {
    },
    today
  }),
  computed: {
    canExport () {
      if (this.locationName === 'All Locations') {
        return this.$store.getters.can('export-full-inventory')
      } else {
        return this.$store.getters.can('export-inventory')
      }
    },
    locationChoices () {
      return ['All Locations'].concat(this.locationMeta.map(x => x.name))
    },
    moveToChoices () {
      return this.locationMeta.filter(x => x.name !== this.locationName).map(x => x.name)
    },
    actionMode () {
      if (this.destinationLocation) return 'MOVE'
      if (this.enableBoxCutting) return 'CUT'
      return null
    },
    isShreddingLocation () {
      const dest = this.locationMeta.find(x => x.name === this.locationName)
      return !!(dest && dest.is_shredding_location)
    }
  },
  watch: {
    selectedLocation (value) {
      if (!value) value = 0 // weird fix for undefined bug
      this.locationName = this.locationChoices[value]
      this.applyFiltersAndRoute()
    }
  },
  methods: {
    rowClickHandler (row) {
      if (this.actionMode === 'MOVE') {
        this.moveInventoryBox(row)
      }
      if (this.actionMode === 'CUT') {
        if (!this.isShreddingLocation) {
          this.$store.commit('setSnackbarWarning', 'This is not a shredding location')
          this.clearModes(true)
          return
        }
        this.markInventoryBoxCut(row)
      }
    },
    applyFiltersAndRoute (resetPage = true) {
      if (resetPage) this.page = 1
      let params = {
        page: this.page.toString()
      }

      if (this.search) params.search = this.search
      if (this.locationName !== 'All Locations') params.location_name = this.locationName
      if (this.destinationLocation) params.destination_location = this.destinationLocation
      if (this.enableBoxCutting) params.cutting = this.enableBoxCutting
      if (this.filterDate) params.filter_date = this.filterDate
      if (this.filterManifestIsNull !== '') params.manifest__isnull = this.filterManifestIsNull

      // prevents duplicate navigation
      if (!_.isEqual(params, this.$route.query)) {
        this.$router.replace({ query: params })
      }
    },
    reflectFilters () {
      const params = this.$route.query
      if (params.filter_date) this.filterDate = params.filter_date
      if (params.search) this.search = params.search
      if (params.location_name) this.locationName = params.location_name
      if (params.destination_location) this.destinationLocation = params.destination_location
      if (params.cutting) this.enableBoxCutting = params.cutting
      if (params.hasOwnProperty('manifest__isnull')) {
        this.filterManifestIsNull = params.manifest__isnull === 'true'
      }
      if (params.page) this.page = Number(params.page)

      if (this.filterDate && this.destinationLocation) {
        this.$store.commit('setSnackbarWarning', 'Viewing historic and moving inventory is not allowed.')
        this.destinationLocation = ''
      }
      if (this.locationName === this.destinationLocation) {
        this.$store.commit('setSnackbarWarning', 'Viewing historic and moving inventory is not allowed.')
        this.destinationLocation = ''
      }
    },
    setPagesLength (itemCount) {
      this.pagesLength = Math.ceil(itemCount / 20)
    },
    /**
     * Uses the query set provided in the route to do a search
     * @param filters
     */
    fetchBoxes (filters) {
      fetchInventoryBoxes({ params: filters }).then(response => {
        if (response.data.results) {
          this.setPagesLength(response.data.count)
          this.data = response.data.results
          this.meta = response.data.meta
        }
      })
    },
    async fetchInventoryExport () {
      const filters = this.$route.query
      this.loadingExportData = true
      const response = await fetchInventoryBoxes({ params: filters }, true, true)
      /* eslint-disable camelcase */
      this.completeExportData = response.data.map(({
        name,
        location_name,
        manifest,
        contains,
        in_lots,
        weight,
        // tare,
        // net,
        // pieces,
        buyers,
        gps_number,
        status_text
      }) => ({
        name,
        status: status_text,
        location: location_name,
        gps: gps_number || '',
        manifest: manifest || '',
        vendors: contains.join('\n'),
        lots: in_lots.join('\n'),
        buyers,
        gross: weight
      }))
      /* eslint-enable camelcase */
      this.loadingExportData = false
      return this.completeExportData
    },
    fetchLocations () {
      return fetchInventoryLocations().then(response => {
        if (response.data) this.locationMeta = response.data
      })
    },
    moveInventoryBox (box) {
      const dest = this.locationMeta.find(x => x.name === this.destinationLocation)
      patchInventoryBox(box.id, { location: dest.url }).then(response => {
        if (response.status === 200) {
          this.data = this.data.filter(b => b.id !== box.id)
          this.$store.commit('setSnackbarInfo', 'Box ' + box.source_id + ' moved to ' + dest.name)
        } else {
          this.$store.commit('setSnackbarError', 'Failed to move Box ' + box.source_id)
        }
      })
    },
    markInventoryBoxCut (box) {
      patchInventoryBox(box.id, { status: 'CU' }).then(response => {
        if (response.status === 200) {
          this.data = this.data.filter(b => b.id !== box.id)
          this.$store.commit('setSnackbarInfo', 'Box ' + box.source_id + ' marked cut')
        } else {
          this.$store.commit('setSnackbarError', 'Failed to mark Box ' + box.source_id + ' cut')
        }
      })
    },
    /**
     * Used to reset any values related to alert "Modes"
     * - prevents bugs where remaining state lingers on unexpected mode change
     */
    clearModes (route = false) {
      this.enableBoxCutting = false
      this.destinationLocation = null
      if (route) this.applyFiltersAndRoute(false)
    },
    /**
     * Sets the destination location and locks locations
     * @param location
     */
    setDestinationLocation (location) {
      this.clearModes()
      this.destinationLocation = location
      this.applyFiltersAndRoute(false)
    },
    setEnableBoxCutting (value) {
      this.clearModes()
      this.enableBoxCutting = value
      this.applyFiltersAndRoute(false)
    }
  },
  mounted () {
    /**
     * gets the filters from the route parameters, then fetches locations.
     * Don't let a non-shredding location be in "CUT" mode
     * Fetch teh boxes
     */
    this.reflectFilters()
    this.fetchLocations().then(() => {
      if (this.actionMode === 'CUT' && !this.isShreddingLocation) {
        this.clearModes()
      }
    })
    // Save the latest location name
    this.$store.commit('setLastInventoryOnHandLocation', this.locationName)
    const params = this.$route.query
    // Prevents All Locations from being passed to fetch boxes
    if (params.location_name === 'All Locations') delete params.location_name
    this.fetchBoxes(params)
  }
}
</script>
