var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ValidationObserver',{ref:"validationObserver",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('edit-dialog',{attrs:{"value":_vm.value,"headline-text":"Split Box","inputs-valid":!invalid,"accept-text":"Split"},on:{"accept":_vm.applyBoxSplit,"input":function($event){return _vm.$emit('input', $event)}}},[_c('p',[_vm._v(" Enter the allocated number of pieces and weight, other fields may be modified after splitting. ")]),_c('v-simple-table',[_c('tbody',[_c('tr',[_c('td'),_c('td',[_vm._v(" "+_vm._s(_vm._f("formatNumber")(_vm.inventoryBox.weight))+" lbs "),_c('v-icon',{attrs:{"small":"","color":_vm.combinedWeight >= _vm.inventoryBox.weight ? 'success' : 'warning'}},[_vm._v(" "+_vm._s(_vm.combinedWeight >= _vm.inventoryBox.weight ? 'mdi-check-circle-outline' : 'mdi-circle-outline')+" ")])],1),_c('td',[_vm._v(" "+_vm._s(_vm._f("formatNumber")(_vm.inventoryBox.pieces))+" "),_c('v-icon',{attrs:{"small":"","color":_vm.combinedPieces >= _vm.inventoryBox.pieces ? 'success' : 'warning'}},[_vm._v(" "+_vm._s(_vm.combinedPieces >= _vm.inventoryBox.pieces ? 'mdi-check-circle-outline' : 'mdi-circle-outline')+" ")])],1)]),_c('tr',[_c('td',[_vm._v(" "+_vm._s(_vm.inventoryBox.name)+" ")]),_c('td',[_c('ValidationProvider',{attrs:{"rules":"required|two_decimal"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"number","label":"Weight lbs","error-messages":errors},model:{value:(_vm.originBoxWeight),callback:function ($$v) {_vm.originBoxWeight=$$v},expression:"originBoxWeight"}})]}}],null,true)})],1),_c('td',[_c('ValidationProvider',{attrs:{"rules":"required|decimal:0"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"number","label":"Pieces","error-messages":errors},model:{value:(_vm.originBoxPieces),callback:function ($$v) {_vm.originBoxPieces=$$v},expression:"originBoxPieces"}})]}}],null,true)})],1)]),_c('tr',[_c('td',[_vm._v("New Box")]),_c('td',[_c('ValidationProvider',{attrs:{"rules":"required|two_decimal"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Weight lbs","type":"number","error-messages":errors},model:{value:(_vm.newBoxWeight),callback:function ($$v) {_vm.newBoxWeight=$$v},expression:"newBoxWeight"}})]}}],null,true)})],1),_c('td',[_c('ValidationProvider',{attrs:{"rules":"required|decimal:0"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Pieces","type":"number","error-messages":errors},model:{value:(_vm.newBoxPieces),callback:function ($$v) {_vm.newBoxPieces=$$v},expression:"newBoxPieces"}})]}}],null,true)})],1)]),_c('tr',[_c('td'),_c('td',[_vm._v(" "+_vm._s(_vm._f("formatNumber")(_vm.combinedWeight))+" lbs ")]),_c('td',[_vm._v(" "+_vm._s(_vm._f("formatNumber")(_vm.combinedPieces))+" ")])])])])],1)]}}])}),(_vm.newBox)?_c('confirm-dialog',{on:{"accept":function () { return _vm.$router.push({ name: 'inventory-on-hand-box', params: { id: _vm.newBox.id } }); }},model:{value:(_vm.navigateToNewBoxDialog),callback:function ($$v) {_vm.navigateToNewBoxDialog=$$v},expression:"navigateToNewBoxDialog"}},[_vm._v(" Do you want to navigate to "+_vm._s(_vm.newBox.name)+"? ")]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }