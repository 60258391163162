<template>
  <div>
    <ValidationObserver
      v-slot="{ invalid }"
      ref="validationObserver"
    >
      <edit-dialog
        :value="value"
        :headline-text="title"
        accept-text="Select"
        :inputs-valid="!!location"
        @input="$emit('input', $event)"
        @accept="selectLocation"
      >
        <slot />
        <v-select
          v-model="location"
          label="Location"
          persistent-hint
          clearable
          item-text="name"
          item-value="url"
          :items="locations"
        />
      </edit-dialog>
    </ValidationObserver>
  </div>
</template>
<script>

export default {
  name: 'SelectLocationDialog',
  props: {
    title: {
      type: String,
      default: 'Select Location'
    },
    value: {
      type: Boolean,
      default: false
    },
    locations: {
      type: Array,
      required: true
    }
  },
  data: () => ({
    location: null
  }),
  methods: {
    selectLocation () {
      this.$emit('selected', this.location)
      this.$emit('input', false)
    }
  }
}
</script>
