<template>
  <unauthorized
    :authorized="$store.getters.can('view-inventory-on-hand')"
    message="Unauthorized to view Inventory on Hand"
  >
    <div v-if="!box">
      <v-skeleton-loader
        :loading="loading"
        type="heading, list-item@5, table"
      >
        Unable to load box
      </v-skeleton-loader>
    </div>
    <v-container
      v-if="box"
      fluid
      class="mb-16"
    >
      <url-breadcrumbs
        :items="[
          ['Inventory On Hand', { name: 'inventory-on-hand' }],
          ['Inventory Box', { name: 'inventory-on-hand-box', params: { id }}]
        ]"
      />
      <v-alert
        v-if="box.status === 'ST'"
        outlined
        dense
        color="warning"
      >
        This box is staged and can only be edited in Lot Inventory.
      </v-alert>
      <v-row>
        <v-col>
          <base-title>
            <span v-if="box && box.name">
              <info-tooltip>
                <template v-slot:wrap-me>
                  {{ box.name }}
                </template>
                #{{ id }}
              </info-tooltip>
            </span>
            <template v-slot:subtitle>
              <div v-if="box && box.inventory">
                <info-tooltip>
                  <template v-slot:wrap-me>
                    <span class="text-sm-caption">
                      <router-link-id
                        :id="box.inventory.id"
                        name="inventory"
                      >
                        {{ box.inventory.name }}
                      </router-link-id>
                    </span>
                  </template>
                  Linked Lot Inventory
                </info-tooltip>
              </div>
              {{ box.buyers.join(', ') }}
            </template>
          </base-title>
        </v-col>
        <v-col class="text-right">
          <print-inventory-box
            v-if="box"
            :usernames="box.buyers"
            :box-name="box.name"
            :location-name="box.location_name || ''"
            :gps-number="box.gps_number || ''"
            :gross="box.weight"
            :net="box.net"
            :tare="box.tare"
            :pieces="String(box.pieces)"
            :vendors-text="box.contains"
            :lots-text="box.in_lots"
          >
            <template v-slot:activator="{ on }">
              <v-btn
                small
                @click="on"
              >
                print
                <v-icon
                  small
                  class="pl-2"
                >
                  mdi-printer-outline
                </v-icon>
              </v-btn>
            </template>
          </print-inventory-box>
        </v-col>
      </v-row>
      <br>
      <v-skeleton-loader v-if="loading" />
      <div v-else-if="!box">
        Unable to load data
      </div>
      <div v-else>
        <h3 class="text-caption grey--text">
          Status
        </h3>
        <p>{{ box.status_text }} <small>({{ box.is_on_hand ? 'On Hand' : 'Not On Hand' }})</small></p>
        <h3 class="text-caption grey--text">
          Catalog
        </h3>
        <p>{{ box.catalog_name || 'No Catalog / Material Type' }}</p>
        <h3 class="text-caption grey--text">
          Location / GPS
        </h3>
        <p>{{ box.location_name || 'No Location' }}<span v-if="box.gps_number"> / {{ box.gps_number }}</span></p>
        <h3 class="text-caption grey--text">
          Notes
        </h3>
        <p>{{ box.notes || '' }}</p>
        <v-skeleton-loader
          :loading="loading"
          type="table"
        >
          <h3 class="text-caption grey--text mb-2">
            Change History
          </h3>
          <inventory-box-table
            v-model="history"
            :headers="[
              'name',
              'status',
              'location_name',
              'manifest',
              'contains',
              'in_lots',
              'catalog_name',
              'weight',
              'tare',
              'net',
              'pieces',
              'eff_start_datetime'
            ]"
            no-name-link
            :historic="true"
          />
        </v-skeleton-loader>
        <div class="text-caption text-right mt-8">
          Last Updated on {{ box.updated_at | formatTimeDate }}
        </div>
      </div>
      <base-speed-dial
        v-if="box.is_on_hand"
        direction="left"
        :icon="this.box.is_locked ? 'mdi-lock' : 'mdi-pencil'"
        open-on-hover
      >
        <!--Edits should be preformed on the linked Inventory Line (at least for now)-->
        <v-btn
          :disabled="!$store.getters.can('edit-inventory-boxes') || this.box.is_locked"
          fab
          color="primary"
          @click="$router.push({ name: 'inventory-on-hand-box-edit', params: { id: box.id }})"
        >
          <v-icon>
            mdi-pencil
          </v-icon>
        </v-btn>
        <v-btn
          :disabled="!$store.getters.can('edit-inventory-boxes') || this.box.is_locked"
          fab
          @click="splitDialogLazy = true; splitDialog = !splitDialog"
        >
          <v-icon>
            mdi-call-split
          </v-icon>
        </v-btn>
        <v-btn
          :disabled="!$store.getters.can('edit-inventory-boxes') || this.box.is_locked"
          fab
          @click="mergeDialogLazy = true; mergeDialog = !mergeDialog"
        >
          <v-icon>
            mdi-call-merge
          </v-icon>
        </v-btn>
        <v-btn
          :disabled="!$store.getters.can('edit-inventory-boxes') || this.box.is_locked"
          fab
          color="error"
          @click="deleteBox"
        >
          <v-icon>
            mdi-delete
          </v-icon>
        </v-btn>
      </base-speed-dial>
    </v-container>
    <merge-box-dialog
      v-if="mergeDialogLazy"
      v-model="mergeDialog"
      :inventory-box="box"
      :search-filters="box.location_name ? { location_name: box.location_name } : {}"
      @merged="loadData"
    />
    <split-location-dialog
      v-if="splitDialogLazy"
      v-model="splitDialog"
      :inventory-box="box"
    />
  </unauthorized>
</template>

<script>
import { deleteInventoryBox, fetchInventoryBox, fetchInventoryBoxHistory } from '../../requests/inventoryBoxes'
import InventoryBoxTable from './components/InventoryBoxTable.vue'
import BaseSpeedDial from '../../components/BaseSpeedDial.vue'
import MergeBoxDialog from './components/MergeBoxDialog.vue'
import { use } from 'chai'
import SplitLocationDialog from './components/SplitBoxDialog.vue'
import PrintInventoryBox from '@/views/Warehouse/components/PrintInventoryBox.vue'

export default {
  name: 'InventoryBoxView',
  components: { PrintInventoryBox, SplitLocationDialog, MergeBoxDialog, BaseSpeedDial, InventoryBoxTable },
  data: () => ({
    id: 0,
    box: null,
    loading: false,
    history: [],
    mergeDialog: false,
    mergeDialogLazy: false,
    splitDialog: false,
    splitDialogLazy: false
  }),
  methods: {
    use,
    deleteBox () {
      if (confirm(`Are you sure you want to delete Inventory Box ${this.box.name}?`)) {
        deleteInventoryBox(this.$route.params.id).then(() => {
          this.loadData()
        })
      }
    },
    async loadData () {
      this.id = this.$route.params.id
      const response = await fetchInventoryBox(this.$route.params.id)
      if (response) {
        this.box = response.data
      }
      const hResponse = await fetchInventoryBoxHistory(this.$route.params.id)
      if (hResponse) {
        this.history = hResponse.data
      }
    }
  },
  async mounted () {
    this.loading = true
    await this.loadData()
    this.loading = false
  }
}
</script>
