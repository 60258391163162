<!--
Create empty Location with just a name
-->
<template>
  <div>
    <ValidationObserver
      v-slot="{ invalid }"
      ref="validationObserver"
    >
      <edit-dialog
        :value="value"
        headline-text="Create Inventory Location"
        :inputs-valid="!invalid && !loading && !conflictingLocation"
        accept-text="Create"
        @accept="createLocation"
        @input="$emit('input', $event)"
      >
        <ValidationProvider
          rules="required|max:100"
          v-slot="{ errors }"
        >
          <v-text-field
            label="Name*"
            v-model="name"
            :error-messages="errors"
          />
        </ValidationProvider>
        <v-data-table
          :headers="headers"
          :items="locations"
          :search="name"
          :loading="loading"
        >
          <template v-slot:item.name="{ item }">
            <span :class="{ 'red--text': item.name.toLowerCase() === name.toLowerCase() }">{{ item.name }}</span> <small class="grey--text">{{ item.id }}</small>
          </template>
        </v-data-table>
      </edit-dialog>
    </ValidationObserver>
  </div>
</template>

<script>
import axios from 'axios'
import { fetchInventoryLocations } from '../requests/inventoryBoxes'

export default {
  name: 'CreateInventoryLocationDialog',
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    headers: [
      { text: 'Name', value: 'name' }
    ],
    name: '',
    loading: true,
    locations: []
  }),
  watch: {
    async value (opened) {
      if (opened) {
        this.name = ''
        this.loading = true
        const response = await fetchInventoryLocations()
        if (response.data) {
          this.locations = response.data
        }
        this.loading = false
      }
    }
  },
  computed: {
    conflictingLocation () {
      return !!this.locations.find(loc => loc.name.toLowerCase() === this.name.toLowerCase())
    }
  },
  methods: {
    createLocation () {
      if (this.name.length === 0) {
        this.$store.commit('setSnackbarError', 'Can not create a location without a name.')
        return
      }
      if (this.conflictingLocation) {
        this.$store.commit('setSnackbarError', 'A location with this name already exists.')
        return
      }
      const payload = {
        name: this.name,
        is_inventory_location: true
      }
      axios.post('/logistics/locations/', payload).then(res => {
        this.$store.commit('setSnackbarSuccess', `Created location ${this.name}`)
        this.$emit('created', res.data)
        this.name = ''
      }).catch(() => {
        this.$store.commit('setSnackbarError', 'Failed to create Location.')
      })
    }
  }
}
</script>
