<template>
  <unauthorized
    :authorized="$store.getters.can('view-inventory-on-hand')"
    message="Unauthorized to view Inventory on Hand"
  >
    <v-container
      fluid
      class="mb-16"
    >
      <url-breadcrumbs
        :items="[
          ['Inventory On Hand', { name: 'inventory-on-hand' }],
          ['Manage Locations', { name: 'inventory-on-hand-locations'}]
        ]"
      />
      <base-title title="Manage Locations" />
      <v-skeleton-loader
        type="table"
        :loading="loading"
      >
        <v-data-table
          :headers="headers"
          :items="locations"
        >
          <template v-slot:item.actions="{ item }">
            <v-btn
              small
              @click="promptDisableLocation(item)"
            >
              Disable Location
            </v-btn>
          </template>
        </v-data-table>
      </v-skeleton-loader>
      <confirm-dialog
        v-model="confirmDialog"
        @accept="disableLocation"
      >
        Disabling a location will remove it as a inventory location and detach it from all current inventory units.
      </confirm-dialog>
    </v-container>
  </unauthorized>
</template>

<script>

import { disableInventoryLocation, fetchInventoryLocations } from '../../requests/inventoryBoxes'
import ConfirmDialog from '../../base/components/ConfirmDialog.vue'

export default {
  name: 'InventoryLocationsManageView',
  components: { ConfirmDialog },
  data: () => ({
    loading: false,
    headers: [
      { text: 'Name', value: 'name' },
      { text: 'Actions', value: 'actions', align: 'right', sortable: false }
    ],
    selectedLocation: null,
    locations: [],
    confirmDialog: false
  }),
  methods: {
    promptDisableLocation (location) {
      this.selectedLocation = location
      this.confirmDialog = true
    },
    disableLocation () {
      disableInventoryLocation(this.selectedLocation.id).then(res => {
        this.selectedLocation = null
        if (res.status === 200) {
          this.$store.commit('setSnackbarSuccess', 'Inventory Location Disabled')
          this.loadData()
        } else {
          this.$store.commit('setSnackbarError', 'Inventory Location Disable Failed')
        }
      })
    },
    loadData () {
      this.loading = true
      fetchInventoryLocations().then(res => {
        if (res.data) this.locations = res.data
        this.loading = false
      })
    }
  },
  mounted () {
    this.loadData()
  }
}
</script>
